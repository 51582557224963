import * as z from "zod";

const req = (max: number = 50) => {
  return z
    .string()
    .min(1, { message: "Required" })
    .max(max, { message: `Max ${50} characters` });
};

const imageSchema = z.object({
  status: z.literal("success"),
  id: z.string().min(1),
  url: z.string().optional(),
  source: z.string(),
  name: z.string(),
  size: z.number(),
});

const preformattedLineSchema = z
  .object({
    SKU: req(16),
    Valid: z.literal(true),
    Image: imageSchema.optional(),
    Quantity: z.number().min(1),
    AssetBleed: z.union([z.string(), z.null(), z.undefined()]),
    Orientation: z.string().optional(),
  })
  .refine((data) => !(data.Image?.id && !data.Orientation), {
    message: "Orientation selection required.",
    path: ["Orientation"],
  });

const shipDetailSchema = z.object({
  Recipient: req(),
  Email: z.string().email(),
  Phone: req(),
  Number: req(16),
  Street: req(100),
  Street2: z.string().max(100).optional(),
  City: req(50),
  Region: req(16),
  Country: req(16),
  PostalCode: req(16),
});

export const schema = z
  .object({
    Account: req(16),
    Lines: z.array(preformattedLineSchema).min(1),
    ShipCustom: z.boolean(),
    ShipTo: z.string(),
    ShipToDetail: shipDetailSchema.optional(),
    PONumber: z.string().max(30, {message: "Max 30 characters"}).optional(),
  })
  .refine(
    (data) => {
      if (!data.ShipCustom && !data.ShipTo) {
        return false;
      } else {
        return true;
      }
    },
    { message: "Select Ship Location", path: ["ShipTo"] }
  )
  .refine(
    (data) => {
      if (data.ShipCustom && !data.ShipToDetail) {
        return false;
      } else {
        return true;
      }
    },
    { message: "Shipping Details Required", path: ["ShipTo"] }
  );
