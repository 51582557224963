import {
  Component,
  Show,
  createResource,
  createSignal,
  Switch,
  Match,
  createMemo,
} from "solid-js";
import {
  UploadFile,
  createFileUploader,
  fileUploader,
} from "@solid-primitives/upload";
import { UploadClient, ProgressCallback } from "@uploadcare/upload-client";
import { debounce } from "@solid-primitives/scheduled";
import { useSessionContext } from "~/utils/contexts";
import { Motion } from "solid-motionone";
import { Icon } from "solid-heroicons";
import {
  xMark,
  check,
  arrowsPointingOut,
  arrowsRightLeft,
  arrowsUpDown,
} from "solid-heroicons/outline";
import { TextFieldInput, SelectBox } from "~/components/inputs";
import { BaseLoader } from "~/components/utility";
import { toasty } from "~/components/utility/toast";
import { CustomOrderLine } from "../types";
import { bytesToMB, isFileImage } from "../helpers";
import { ImageDropzone } from "./";

type CustomSkuLineProps = {
  index: number;
  line: () => CustomOrderLine;
  deleteLine: VoidFunction;
  setter: (...args: any) => void;
  setErrors: (...args: any) => void;
  errors: () => any;
};

export const CustomSkuLine: Component<CustomSkuLineProps> = (props) => {
  const [skuVal, setSkuVal] = createSignal<string>();
  const [uploadedImgOrientation, setUploadedImgOrientation] = createSignal<
    "portrait" | "landscape" | "square"
  >();
  const { session } = useSessionContext();
  const errors = () => props.errors().Lines?.[props.index];
  const { files, selectFiles } = createFileUploader({
    multiple: false,
    accept: "image/*",
  });
  fileUploader;

  // SKU data
  const searchSku = debounce((val: string) => {
    setSkuVal(val);
  }, 500);

  const qty = createMemo(() => {
    return props.line().Quantity;
  });

  let controller: AbortController;
  const [skuData] = createResource(
    () => (skuVal() || skuVal() === "" ? [skuVal(), qty()] : false),
    async ([sku, qty]) => {
      if (controller) {
        controller.abort();
      }
      controller = new AbortController();

      const payload = {
        Type: "product",
        Quantity: qty,
        SubItems: [
          {
            SKU: sku,
          },
        ],
      };

      try {
        const response = await fetch(
          `${import.meta.env.VITE_ROMA_API}/cart/estimate`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${session()?.token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify([payload]),
            signal: controller.signal,
          }
        );

        if (response.ok) {
          const data = await response.json();
          props.setter(`Lines.${props.index}`, (prev: CustomOrderLine) => ({
            ...prev,
            Valid: true,
            Amount: data[0].Amount.toFixed(2),
            Discount: data[0].Discount.toFixed(2),
            Tax: data[0].Tax.toFixed(2),
          }));

          return { ...data[0], Valid: true };
        } else {
          const error = await response.json();
          throw new Error(error.Message);
        }
      } catch (error) {
        console.log(error);
        props.setter(`Lines.${props.index}.Valid`, false);
        return { Valid: false };
      }
    },
    {
      ssrLoadFrom: "initial",
      initialValue: { Valid: false },
    }
  );

  const bleedOptions = [
    { label: "None (Default)", value: "none" },
    { label: "Stretch", value: "stretch" },
    { label: "Mirror", value: "mirror" },
    { label: "White", value: "white" },
    { label: "Black", value: "black" },
  ];

  const orientationOptions = [
    { label: "Portrait", value: "portrait" },
    { label: "Landscape", value: "landscape" },
    { label: "Square", value: "square" },
  ];

  const addImage = (data: UploadFile[]) => {
    if (data) {
      props.setter(`Lines.${props.index}.Image`, data[0]);
      uploadImage();
    }
  };

  // UploadCare
  const client = new UploadClient({
    publicKey: import.meta.env.VITE_UPLOADCARE_ARTSHOP_PUBLIC,
  });

  const uploadImage = async () => {
    const file = props.line().Image!.file;

    // helper function to set status/progress/uuid/cdnUrl
    const setImageProperty = (
      property: string,
      value: string | number | null | undefined
    ) => {
      props.setter(`Lines.${props.index}.Image.${property}`, value);
    };

    const onProgress: ProgressCallback = (data) => {
      if (data.isComputable) {
        const progress = Math.trunc(data.value * 100);
        setImageProperty("progress", progress);
      }
    };

    //? set an uploading status
    setImageProperty("status", "uploading");

    try {
      const result = await client.uploadFile(file, { onProgress });

      if (!result.isImage) {
        throw new Error();
      }
      //? do something with uuid and cdnUrl:
      setImageProperty("id", result.uuid);
      setImageProperty("url", result.cdnUrl);

      if (result.imageInfo?.width && result.imageInfo?.height) {
        const val = result.imageInfo.width / result.imageInfo.height;
        const orientation =
          val === 1 ? "square" : val > 1 ? "landscape" : "portrait";

        setImageProperty("imageWidth", result.imageInfo?.width);
        setImageProperty("imageHeight", result.imageInfo?.height);

        setUploadedImgOrientation(orientation);
      }

      //? set success status
      setImageProperty("status", "success");
    } catch (error) {
      //? set error status, show a toast?
      setImageProperty("status", "error");

      setImageProperty("imageWidth", undefined);
      setImageProperty("imageHeight", undefined);

      toasty.show({
        title: "Upload Error",
        message: `Error uploading ${file.name}`,
        type: "warning",
      });
    }
  };

  const skuStartsWithMC = createMemo(() => {
    if (props.line().SKU && skuData.latest.Valid) {
      return props.line().SKU.startsWith("MC");
    }
    return false;
  });

  const orientationMismatch = createMemo(() => {
    if (
      props.line().Image &&
      props.line().Orientation &&
      uploadedImgOrientation()
    ) {
      return props.line().Orientation !== uploadedImgOrientation();
    }
    return false;
  });

  return (
    <Motion.div
      initial={props.index === 0 ? undefined : { opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.3 }}
    >
      <div
        class={`border border-gray-300 shadow-sm rounded-md p-4  grid sm:grid-cols-3 gap-2 items-start relative ${
          errors()?.Valid || props.line().Valid === false
            ? "bg-red-50"
            : "bg-faint-blue"
        }`}
      >
        <button
          type="button"
          onClick={props.deleteLine}
          class="absolute top-1 right-1 flex items-center justify-center w-5 h-5 rounded-md border border-gray-300 text-gray-400 hover:bg-white hover:text-roma-dark-grey  disabled:cursor-not-allowed"
          disabled={props.index === 0}
        >
          <Icon path={xMark} class="w-4" stroke-width={1.75} />
        </button>
        <TextFieldInput
          name={`Lines.${props.index}.SKU`}
          label="SKU"
          placeholder="Enter Product SKU"
          onChange={searchSku}
          validationState={
            props.line().SKU && !skuData.latest.Valid ? "invalid" : "valid"
          }
          error="Invalid SKU"
        />
        <div class="flex flex-col gap-2">
          <label for="upload" class="text-sm font-bold text-roma-dark-grey">
            Image
          </label>
          <div class="flex">
            <button
              name="upload"
              type="button"
              class="grow border border-gray-300 bg-white rounded-md h-[2.4rem] text-sm text-roma-dark-grey disabled:cursor-not-allowed"
              onClick={() => {
                selectFiles((files) => {
                  if (files) {
                    addImage(files);
                  }
                });
              }}
              disabled={!skuData.latest.Valid}
            >
              {props.line().Image ? "Change Image" : "Add Image"}
            </button>
            <Show when={props.line().Image}>
              <button
                type="button"
                class="ml-1 h-[2.4rem] w-[2.4rem] bg-white border border-gray-300 hover:border-neutral-400 text-roma-medium-grey hover:text-roma-dark-grey rounded-md flex items-center justify-center"
                onClick={() => {
                  props.setter(`Lines.${props.index}.Image`, undefined);
                }}
              >
                <Icon path={xMark} class="w-5 h-5" />
              </button>
            </Show>
          </div>

          <Show when={props.line().Image}>
            <div class="flex items-center">
              <SelectBox
                options={orientationOptions}
                class="grow"
                triggerClass="bg-white"
                defaultValue="none"
                inlineTitle="Orientation:"
                inlinePlaceholder="Please select"
                onChange={(option) => {
                  props.setter(
                    `Lines.${props.index}.Orientation`,
                    option.value
                  );
                }}
                validationState={
                  props.errors().Lines?.[props.index].Orientation
                    ? "invalid"
                    : "valid"
                }
                error="Select desired orientation"
              />

              <Show when={props.line().Orientation}>
                <div class="ml-1 w-[2.4rem] h-[2.4rem] flex justify-center items-center border border-gray-300 rounded-md bg-roma-grey">
                  <div
                    class="bg-white border-roma-dark-grey text-roma-dark-grey border rounded-[3px] flex items-center justify-center transition-[height,_width] delay-200"
                    classList={{
                      "w-7 h-5": props.line().Orientation === "landscape",
                      "w-5 h-7": props.line().Orientation === "portrait",
                      "w-5 h-5": props.line().Orientation === "square",
                    }}
                  >
                    <Icon
                      path={
                        props.line().Orientation === "square"
                          ? arrowsPointingOut
                          : props.line().Orientation === "landscape"
                          ? arrowsRightLeft
                          : arrowsUpDown
                      }
                      class="w-4 h-4"
                    />
                  </div>
                </div>
              </Show>
            </div>

            <Show when={skuStartsWithMC()}>
              <SelectBox
                options={bleedOptions}
                triggerClass="bg-white"
                defaultValue="none"
                inlineTitle="Bleed:"
                onChange={(option) => {
                  const val = option.value === "none" ? null : option.value;
                  props.setter(`Lines.${props.index}.AssetBleed`, val);
                }}
              />
            </Show>
          </Show>
        </div>
        <div class="grid grid-cols-[50px_auto] gap-2">
          <TextFieldInput
            name={`Lines.${props.index}.Quantity`}
            label="Qty"
            type="number"
            value={props.line().Quantity as string}
            defaultValue="1"
            validationState={errors()?.Quantity ? "invalid" : "valid"}
            error={"Invalid"}
          />
          <TextFieldInput
            name={`Lines.${props.index}.Tag`}
            label="Tag Item"
            placeholder="Optional Tag Name"
            maxLength={50}
          />

          <Show when={orientationMismatch()}>
            <div class="col-span-full px-3 py-2 border text-xs border-gray-300 rounded-md bg-red-50 w-full">
              The orientation of the image you uploaded does not match your
              selection. Please ensure the uploaded image is in the desired
              orientation.
            </div>
          </Show>
        </div>

        <Show when={skuData.latest.Valid || props.line().Image}>
          <Motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            class="col-span-full border border-gray-300 rounded-md bg-white grid sm:grid-cols-3 max-sm:px-2 gap-2 text-xs text-roma-dark-grey py-3"
          >
            {/* SKU DETAILS */}
            <div class="sm:pl-3">
              <Show
                when={skuData.latest.Valid}
                fallback={<p class="text-orange-500">Enter a Product SKU</p>}
              >
                <Show when={skuData.latest.Name}>
                  <p>{skuData.latest.Name}</p>
                </Show>
              </Show>
            </div>
            {/* IMAGE DETAILS */}
            <div class="grid grid-cols-[min-content_auto]">
              <Show
                when={props.line().Image}
                fallback={
                  <ImageDropzone
                    onDrop={(files) => {
                      if (files.length > 1) {
                        toasty.show({
                          title: "Upload Error",
                          message: "Maximum 1 image can be uploaded",
                          type: "warning",
                        });
                        return;
                      } else if (files.length === 0) {
                        return;
                      }
                      if (isFileImage(files[0])) {
                        addImage(files);
                      } else {
                        toasty.show({
                          title: "Upload Error",
                          message: "Invalid file type",
                          type: "warning",
                        });
                      }
                    }}
                  />
                }
              >
                {(img) => (
                  <>
                    <a
                      href={img().source}
                      target="_blank"
                      class="w-16 h-16 mr-2 border border-gray-300 rounded-md overflow-hidden relative"
                    >
                      <img
                        src={img().source}
                        alt=""
                        class="w-full h-full object-cover"
                      />
                      <Show when={img().status}>
                        {(status) => (
                          <div class="absolute bottom-1 right-1 h-6 aspect-square bg-white/80 rounded-[4px] text-xs flex items-center justify-center">
                            <Switch>
                              <Match when={status() === "success"}>
                                <Icon
                                  path={check}
                                  stroke-width={3}
                                  class="w-4 h-4 text-success-green"
                                />
                              </Match>
                              <Match when={status() === "error"}>
                                <div class="px-1 w-full flex items-center">
                                  <p class="text-red-500 text-[10px] font-bold">
                                    ERROR
                                  </p>
                                  <Icon
                                    path={xMark}
                                    class="w-3 h-3 text-red-500"
                                    stroke-width={3}
                                  />
                                </div>
                              </Match>
                              {/* UPLOADING && PROGRESS */}
                              <Match when={status() === "uploading"}>
                                <Show
                                  when={img().progress}
                                  fallback={
                                    <BaseLoader
                                      width={3}
                                      height={3}
                                      class="!mr-0"
                                    />
                                  }
                                >
                                  <span class="px-1">{img().progress}%</span>
                                </Show>
                              </Match>
                            </Switch>
                          </div>
                        )}
                      </Show>
                    </a>
                    <div>
                      <p class="line-clamp-1 hover:whitespace-nowrap hover:bg-white hover:line-clamp-none">
                        Name: {img().file.name}
                      </p>
                      <p>Size: {bytesToMB(img().file.size)}</p>
                      <p>Type: {img().file.type}</p>
                    </div>
                  </>
                )}
              </Show>
            </div>
            {/* PRICE */}
            {/* <div class="flex flex-col"> */}
            <div class="self-end text-right pr-3 text-sm">
              <Show when={skuData.latest.Valid}>
                <p>Amount: ${skuData.latest.Amount.toFixed(2)}</p>
              </Show>
            </div>
            {/* </div> */}
            {/* <div class="self-end text-right pr-3 text-sm">
              <Show when={skuData.latest.Valid}>
                <input
                  type="hidden"
                  name={`Lines.${props.index}.Amount`}
                  value={skuData.latest.Amount.toFixed(2)}
                />
                <input
                  type="hidden"
                  name={`Lines.${props.index}.Tax`}
                  value={skuData.latest.Tax.toFixed(2)}
                />
                <input
                  type="hidden"
                  name={`Lines.${props.index}.Discount`}
                  value={skuData.latest.Discount.toFixed(2)}
                />
                <p>Amount: ${skuData.latest.Amount.toFixed(2)}</p>
              </Show>
            </div> */}
          </Motion.div>
        </Show>
      </div>
    </Motion.div>
  );
};
