import {
  Show,
  useContext,
  Index,
  createMemo,
  Switch,
  Match,
  createSignal,
  createResource,
  Suspense,
} from "solid-js";
import { useSearchParams, A } from "@solidjs/router";
// import routeData from "./art-fulfillment.data";
import {
  SiteContext,
  useSiteContext,
  useSessionContext,
} from "~/utils/contexts";
import { createForm } from "@felte/solid";
import { validator } from "@felte/validator-zod";
import { Motion } from "solid-motionone";
import { Icon } from "solid-heroicons";
import { plus, checkCircle, faceFrown } from "solid-heroicons/outline";
import Body from "~/components/Body";
import { TextFieldInput, SelectBox } from "~/components/inputs";
import { BaseLoader } from "~/components/utility";
import { Modal } from "~/components/utility";

import { schema, formatLineItem } from "~/components/ordering/art-fulfillment";
import {
  ShipToEntry,
  // CustomSkuLine,
} from "~/components/ordering/art-fulfillment/components";
import type {
  CustomOrderFormBody,
  AccountShipTo,
  FormattedPayloadLine,
} from "~/components/ordering/art-fulfillment/types";

import { clientOnly } from "@solidjs/start";
const ClientCustomSkuLine = clientOnly(()=>import("~/components/ordering/art-fulfillment/components").then((mod)=>({default: mod.CustomSkuLine})));
// CustomSkuLine


export default function ArtFulfillment() {
  const { isPartner, session, permission } = useSessionContext();
  const [, setParams] = useSearchParams();
  const [selectedAcct, setSelectedAcct] = createSignal<string | number>();
  const [submitted, setSubmitted] = createSignal<
    "success" | "error" | "loading"
  >();

  // * RESOURCES * //
  // TODO - convert to createAsyncs / actions...
  const [account] = createResource(
    () => isPartner(),
    async () => {
      try {
        const response = await fetch(
          `${import.meta.env.VITE_ROMA_API}/account/profile`,
          {
            headers: {
              Authorization: `Bearer ${session()?.token}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          // if the user has Related accounts, add its own to the beginning of array:
          if (data.Related?.length > 0) {
            data.Related.unshift(data.CustomerNumber);
          }
          return data;
        } else {
          const error = await response.json();
          throw new Error(error.Message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  );

  const [altShipTos] = createResource(
    () => isPartner() && selectedAcct(),
    async () => {
      try {
        const response = await fetch(
          `${
            import.meta.env.VITE_ROMA_API
          }/admin/customers/${selectedAcct()}/info`,
          {
            headers: {
              Authorization: `Bearer ${session()?.token}`,
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          const shipTos = data.ShipTos.map((item: AccountShipTo) => {
            if (item.Name == "CUSTOM") {
              return {
                value: "CUSTOM",
                label: "Ship to another address",
                id: item.AccountID,
              };
            }
            return {
              value: item.AccountID,
              label: [
                item.Street,
                item.City,
                item.Region,
                item.Postal,
                item.Country,
              ].join(", "),
              id: item.AccountID,
            };
          });
          return shipTos;
        } else {
          const error = await response.json();
          throw new Error(error.Message);
        }
      } catch (error) {
        console.log(error);
      }
    },
    { ssrLoadFrom: "initial", initialValue: undefined }
  );

  // * FORM * //

  const {
    form,
    data,
    setData,
    unsetField,
    errors,
    setErrors,
    addField,
    isValid,
    isValidating,
  } = createForm<CustomOrderFormBody>({
    initialValues: {
      Account: account?.latest?.CustomerNumber,
      ShipCustom: false,
      Lines: [{ SKU: "", Quantity: 1 }],
    },
    extend: validator({ schema }),
    onSubmit: async (values) => {
      const obj = {
        ...values,
      };

      if (obj.ShipCustom) {
        obj.ShipTo = undefined;
      } else {
        obj.ShipToDetail = undefined;
      }
      // if a user has multiple accounts, "AccountID" is used as an override on
      // the API-side to force the order under that account. Not sending this value
      // on accounts that do not have other related accounts
      if (userHasRelatedAccounts()) {
        obj.CustomerNumber = values.Account;
      }
      // filter out invalid lines
      obj.Lines = values.Lines.filter((line) => line.Valid);
      // format lines into SAP structure
      (obj.Lines as unknown as FormattedPayloadLine[]) = obj.Lines.map(
        (line) => {
          return formatLineItem(line);
        }
      );

      setSubmitted("loading");

      try {
        const response = await fetch(
          `${import.meta.env.VITE_ROMA_API}/orders`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${session()?.token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(obj),
          }
        );

        if (response.ok) {
          const data = await response.json();
          setSubmitted("success");
          if (data.ID) {
            const url = `/checkout/${data.ID}/status`;
            // TODO: investigate why navigate() was not working
            // id was undefined when using navigate, window.location
            // is a workaround
            window.location.href = url;
            // navigate(url, { replace: true });
          }
        } else {
          const error = await response.json();
          throw new Error(error.Message);
        }
      } catch (error) {
        console.log(error);
        setSubmitted("error");
      }
    },
  });
  form;

  // * FNs & MEMOS * //

  const deleteLine = (index: number): void => {
    // check if line has any valuable data:
    const hasData = (): boolean => {
      const line = () => data().Lines[index];
      return (
        (line().SKU && line().SKU !== "") || !!line().Image || !!line().Tag
      );
    };
    let ok = true;
    // if line has valuable data, confirm deletion with user
    if (hasData()) {
      ok = confirm(`Are you sure you want to remove line ${index + 1}?`);
    }
    if (ok) unsetField(`Lines.${index}`);
  };

  const userHasRelatedAccounts = createMemo(() => {
    return account.latest?.Related?.length > 0;
  });

  const totals = createMemo(() => {
    const obj = {
      SubTotal: 0,
      Discount: 0,
      Tax: 0,
      Total: 0,
    };

    if (!data()?.Lines) return obj;

    for (const line of data().Lines) {
      if (line.Valid) {
        obj.SubTotal += +line.Amount!;
        obj.Tax += +line.Tax!;
        obj.Discount += Math.abs(+line.Discount!);
      }
    }

    obj.Total = obj.SubTotal - obj.Discount + obj.Tax;

    return obj;
  });

  return (
    <Suspense>
      <Body>
        {/* MODAL */}
        <Modal
          open={() => !!submitted()}
          onClose={() => setSubmitted(undefined)}
        >
          <Switch>
            <Match when={submitted() === "loading"}>
              <div class="flex justify-center my-8">
                <BaseLoader width={10} height={10} />
              </div>
              <p>Your order is being submitted. Please remain on this page.</p>
            </Match>
            <Match when={submitted() === "success"}>
              <div class="flex justify-center my-6">
                <Icon path={checkCircle} class="w-16 text-success-green" />
              </div>
              <div class="text-center flex flex-col gap-4">
                <p class="text-xl">Your order has been successfully placed!</p>
                <p>You'll receive an email confirmation shortly.</p>
                <div class="text-sm flex items-center justify-center">
                  <span>
                    <BaseLoader width={4} />{" "}
                  </span>
                  <p>Redirecting to confirmation page...</p>
                </div>
              </div>
            </Match>
            <Match when={submitted() === "error"}>
              <div class="flex justify-center my-6">
                <Icon path={faceFrown} class="w-16 text-red-400" />
              </div>
              <div class="text-center flex flex-col gap-4">
                <p class="text-xl">Something went wrong!</p>
                <p>
                  Please try again shortly, or{" "}
                  <A href="/support" class="text-roma-blue">
                    contact support
                  </A>{" "}
                  for more assistance.
                </p>
                <p>We apologize for the inconvenience.</p>
              </div>
            </Match>
          </Switch>
        </Modal>
        {/* MAIN */}
        <main class="py-10 mx-auto max-w-4xl space-y-4">
          <h2 class="text-3xl font-bold">Art Fulfillment</h2>
          <p class="pb-4">
            Welcome to our new Art Fulfillment Page. To maximize your
            experience, please have your Fulfillment SKU’s ready for submission.
            For additional insights into this program and its numerous
            advantages, don’t hesitate to reach out to our dedicated Customer
            Care Team at <a href="tel:18002632322">1-800-263-2322</a>. We’re
            here to support you.
          </p>

          {/* DEBUGGING */}
          {/* <div class="text-orange-500">
          <p class="pb-2"> Submitted: {submitted() ? submitted() : "---"}</p>
          <label for="debugger">Debugger</label>
          <input
            type="checkbox"
            name="debugger"
            id="debugger"
            class="peer sr-only"
          />
          <p>IS VALID?: {isValid() ? "TRUE" : "FALSE"}</p>
          <p>IS VALIDATING?: {isValidating() ? "TRUE" : "FALSE"}</p>
          <p
            class=" hidden peer-checked:block text-xs border border-orange-500 text-orange-500 whitespace-pre"
            innerText={JSON.stringify(data(), null, "   ")}
          />
        </div> */}
          {/* END DEBUGGING */}

          <Show
            when={isPartner() && account.latest && permission.PLACEORDER}
            fallback={
              <Show
                when={isPartner()}
                fallback={
                  <section class="my-4 bg-roma-grey rounded-md p-4 flex items-center justify-center min-h-[40vh] border">
                    <p>
                      Please{" "}
                      <button
                        type="button"
                        class="text-roma-blue"
                        onClick={() => setParams({ signIn: true })}
                      >
                        sign in
                      </button>{" "}
                      to open a submit a custom order.
                    </p>
                  </section>
                }
              >
                <div class=" py-10 px-2">
                  <div class="bg-roma-grey rounded-md max-w-2xl min-h-[60vh] mx-auto shadow-sm flex items-center justify-center px-4">
                    <p class="max-w-md text-center">
                      Your Roma Partner account is not set up to place orders.
                      Please{" "}
                      <A href="/support" class="text-roma-blue">
                        contact support
                      </A>{" "}
                      for more details.
                    </p>
                  </div>
                </div>
              </Show>
            }
          >
            <form use:form class="bg-roma-grey rounded-md p-4 space-y-2 border">
              <h3 class="text-xl font-bold pb-2">Order Details</h3>
              <div class="grid md:grid-cols-3 gap-2 !mb-8">
                <TextFieldInput
                  name="PONumber"
                  label="PO Number (30 character max)"
                  placeholder="PO Number"
                  maxLength={30}
                  validationState={errors().PONumber ? "invalid" : "valid"}
                  error={errors().PONumber}
                />
                {/* ACCOUNT */}
                <Switch>
                  <Match when={userHasRelatedAccounts()}>
                    <SelectBox
                      label="Account"
                      name="Account"
                      options={account().Related.map(
                        (storeNum: string, i: number) => ({
                          value: storeNum,
                          label: storeNum + (i === 0 ? " (Default)" : ""),
                        })
                      )}
                      onChange={(option) => {
                        setSelectedAcct(option.value as string);
                      }}
                      defaultValue={account.latest.CustomerNumber}
                      triggerClass="bg-white"
                      valueClass="line-clamp-1"
                      placeholder="Select store"
                      hiddenSelect
                      disallowEmptySelection
                    />
                  </Match>
                  <Match when={!userHasRelatedAccounts()}>
                    <div class="text-sm text-roma-dark-grey">
                      <p class="font-bold pb-2">Account</p>
                      <div class="bg-white h-[2.4rem] border border-gray-300 rounded-md px-3 flex items-center">
                        <span>{account.latest.CustomerNumber}</span>
                      </div>
                    </div>
                  </Match>
                </Switch>
                {/* SHIPTO */}
                <Switch>
                  <Match
                    when={
                      userHasRelatedAccounts() &&
                      altShipTos.latest !== undefined
                    }
                  >
                    <SelectBox
                      label="Ship To"
                      options={altShipTos.latest}
                      triggerClass="bg-white"
                      valueClass="line-clamp-1"
                      placeholder="Select ship option"
                      onChange={(option) => {
                        if (option.value === "CUSTOM") {
                          setData("ShipCustom", true);
                        } else {
                          setData("ShipCustom", false);
                          setData("ShipToDetail", undefined);
                        }
                        setData("ShipTo", option.value as string);
                      }}
                      validationState={errors()?.ShipTo ? "invalid" : "valid"}
                      error={errors()?.ShipTo}
                      disallowEmptySelection
                    />
                  </Match>
                  <Match
                    when={
                      !userHasRelatedAccounts() ||
                      (account()?.ShipTos && altShipTos.latest === undefined)
                    }
                  >
                    <Show
                      when={!altShipTos.loading}
                      fallback={
                        <div>
                          <p class="text-sm font-bold text-roma-dark-grey pb-2">
                            Ship To
                          </p>
                          <div class="bg-white flex items-center justify-center border border-gray-300 rounded-md h-[2.4rem] ">
                            <BaseLoader width={5} height={5} />
                          </div>
                        </div>
                      }
                    >
                      <SelectBox
                        label="Ship To"
                        options={account.latest.ShipTos.map(
                          (item: AccountShipTo) => {
                            if (item.Name == "CUSTOM") {
                              return {
                                value: "CUSTOM",
                                label: "Ship to another address",
                                id: item.AccountID,
                              };
                            }
                            return {
                              value: item.AccountID,
                              label: [
                                item.Street,
                                item.City,
                                item.Region,
                                item.Postal,
                                item.Country,
                              ].join(", "),
                              id: item.AccountID,
                            };
                          }
                        )}
                        triggerClass="bg-white"
                        valueClass="line-clamp-1"
                        placeholder="Select ship option"
                        onChange={(option) => {
                          if (option.value === "CUSTOM") {
                            setData("ShipCustom", true);
                          } else {
                            setData("ShipCustom", false);
                            setData("ShipToDetail", undefined);
                          }
                          setData("ShipTo", option.id as string);
                        }}
                        validationState={errors()?.ShipTo ? "invalid" : "valid"}
                        error={errors()?.ShipTo}
                        disallowEmptySelection
                      />
                    </Show>
                  </Match>
                </Switch>
              </div>
              {/* CUSTOM SHIPPING ADDRESS */}
              <Show when={data().ShipCustom === true}>
                <h3 class="text-xl font-bold pb-2 pt-3">Shipping Details</h3>

                <Motion.div
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3 }}
                  class="space-y-2"
                >
                  <ShipToEntry namePrefix="ShipToDetail" errors={errors} />
                </Motion.div>
              </Show>{" "}
              <hr />
              {/* LINES */}
              <h3 class="text-xl font-bold pb-2 pt-3">Line Items</h3>
              <Index each={data().Lines}>
                {(line, index) => (
                  <ClientCustomSkuLine
                    index={index}
                    line={line}
                    setter={setData}
                    deleteLine={() => deleteLine(index)}
                    errors={errors}
                    setErrors={setErrors}
                  />
                )}
              </Index>
              <button
                type="button"
                class="py-2 px-3 !mt-3 text-sm rounded-md border border-gray-300 shadow-sm hover:border-neutral-400 bg-faint-blue text-roma-dark-grey flex items-center justify-center"
                onClick={() => addField("Lines", { SKU: "", Quantity: 1 })}
              >
                <Icon path={plus} class="w-4 mr-2" stroke-width={2} />
                <span>Add Line Item</span>
              </button>
              <div class="flex sm:justify-end text-sm sm:!-mt-9">
                <div class="max-sm:grow border border-gray-300 rounded-md bg-white p-4 grid grid-cols-2 text-right shadow-sm">
                  <span>Subtotal:</span>
                  <span>$ {totals().SubTotal.toFixed(2)}</span>
                  <span>Discount:</span>
                  <span>$ {totals().Discount.toFixed(2)}</span>
                  <span>Tax:</span>
                  <span>$ {totals().Tax.toFixed(2)}</span>
                  <span>Total:</span>
                  <span>$ {totals().Total.toFixed(2)}</span>
                </div>
              </div>
              <button
                type="submit"
                class="!mt-8 w-full shadow-lg bg-roma-blue rounded-md text-white py-2 px-3 text-center disabled:bg-roma-dark-grey disabled:cursor-not-allowed"
                disabled={!isValid()}
              >
                Complete & Submit Order
              </button>
            </form>
          </Show>
        </main>
      </Body>
    </Suspense>
  );
}
