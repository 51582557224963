import { Component } from "solid-js";
import { createDropzone, UploadFile } from "@solid-primitives/upload";

type ImageDropzoneProps = {
  onDrop: (files: UploadFile[]) => void;
};

export const ImageDropzone: Component<ImageDropzoneProps> = (props) => {
  const { setRef: dropzoneRef } = createDropzone({
    onDrop: async (files) => {
      props.onDrop(files);
    },
  });

  return (
    <div
      ref={dropzoneRef}
      class="bg-gray-50 col-span-full border-2 border-dashed px-2 text-center h-20 rounded-md flex flex-col items-center justify-center text-roma-dark-grey"
    >
      <p>No image selected</p>
      <p>Drop image or click 'Add Image'</p>
    </div>
  );
};
