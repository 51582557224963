import { Component, createMemo, createSignal } from "solid-js";
import { SelectBox, TextFieldInput } from "~/components/inputs";
import { getRegionsByCountryCode } from "~/utils/countries-states";

export const ShipToEntry: Component<{
  namePrefix?: string;
  errors: () => any;
}> = (props) => {
  const prefix = () => {
    if (props.namePrefix) return props.namePrefix + ".";
    return "";
  };
  const [country, setCountry] = createSignal<"CA" | "US">();

  const errors = () => {
    if (!props.namePrefix) return props.errors();
    return props.errors()?.[props.namePrefix];
  };

  const regionsList = createMemo(() => {
    if (!country()) return [];
    return (
      getRegionsByCountryCode(country() as "CA" | "US") as {
        name: string;
        abbreviation: string;
      }[]
    ).map((region) => ({ label: region.name, value: region.abbreviation }));
  });

  return (
    <>
      <div class="grid sm:grid-cols-3 gap-1">
        <TextFieldInput
          name={`${prefix()}Recipient`}
          label="Recipient"
          validationState={errors()?.Recipient ? "invalid" : "valid"}
          error={errors()?.Recipient}
        />
        <TextFieldInput
          name={`${prefix()}Email`}
          label="Email"
          validationState={errors()?.Email ? "invalid" : "valid"}
          error={errors()?.Email}
        />
        <TextFieldInput
          name={`${prefix()}Phone`}
          label="Phone"
          validationState={errors()?.Phone ? "invalid" : "valid"}
          error={errors()?.Phone}
        />
      </div>
      <div class="grid grid-cols-[1fr_3fr] gap-1">
        <TextFieldInput
          name={`${prefix()}Number`}
          label="Number"
          validationState={errors()?.Number ? "invalid" : "valid"}
          error={errors()?.Number}
        />
        <TextFieldInput
          name={`${prefix()}Street`}
          label="Street"
          validationState={errors()?.Street ? "invalid" : "valid"}
          error={errors()?.Street}
        />
      </div>
      <div class="grid sm:grid-cols-2 gap-1">
        <TextFieldInput name={`${prefix()}Street2`} label="Street Detail" />
        <TextFieldInput
          name={`${prefix()}City`}
          label="City"
          validationState={errors()?.City ? "invalid" : "valid"}
          error={errors()?.City}
        />
      </div>
      <div class="grid sm:grid-cols-3 gap-1">
        <SelectBox
          hiddenSelect
          name={`${prefix()}Country`}
          label="Country"
          options={[
            { label: "Canada", value: "CA" },
            { label: "USA", value: "US" },
          ]}
          onChange={(option) => {
            setCountry(option.value as "CA" | "US");
          }}
          triggerClass="bg-white"
          placeholder="Select ship option"
          validationState={errors()?.Country ? "invalid" : "valid"}
          error={errors()?.Country}
        />
        <SelectBox
          hiddenSelect
          name={`${prefix()}Region`}
          label="Region"
          options={regionsList()}
          triggerClass="bg-white"
          placeholder="Select ship option"
          disabled={!country()}
          validationState={errors()?.Region ? "invalid" : "valid"}
          error={errors()?.Region}
        />
        <TextFieldInput
          name={`${prefix()}PostalCode`}
          label="Postal / Zip"
          validationState={errors()?.PostalCode ? "invalid" : "valid"}
          error={errors()?.PostalCode}
        />
      </div>
    </>
  );
};
