import { CustomOrderLine, FormattedPayloadLine } from "./types";
import { UploadFile } from "@solid-primitives/upload";

export const formatLineItem = (line: CustomOrderLine): FormattedPayloadLine => {
  const defaultObj: FormattedPayloadLine = {
    Type: "product",
    Quantity: 1,
    Plant: "",
    Tag: line.Tag,
    SubItems: [
      {
        SKU: line.SKU
      },
    ],
  };

  const formatted: typeof defaultObj & {
    AssetID?: string;
    AssetSource?: string;
    AssetBleed?: string;
    AssetOrientation?: string;
  } = defaultObj;

  if (line.Image && line.Image.status === "success") {
    formatted.AssetID = line.Image.id;
    formatted.AssetSource = "uploadcare";
    formatted.AssetBleed = line.AssetBleed;
    formatted.AssetOrientation = line.Orientation;
  }

  return formatted;
};

export const bytesToMB = (bytes: number) => {
  if (!bytes) return "";
  return (bytes / 1000000).toString() + "MB";
};

export const isFileImage = (file: UploadFile): boolean => {
  const allowedTypes = [
    "image/jpeg",
    "image/png",
    "image/webp",
  ];
  return allowedTypes.includes(file.file.type);
};
